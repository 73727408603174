html {
  background-color: #fafaf9;
}

html.dark {
  background-color: #064e3b;
}

.game-title {
  font-family: 'PT Serif', serif;
}
